@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;600;700&family=Open+Sans:wght@400;700&display=swap");

:root {
  --digilab: #162448;
  --ocean: #009fe3;
  --spring: #7db928;
  --sunflower: #ffb500;
  --tidal: #6ee7b7;
  --good: #acedab60;
  --bad: #eb6e7360;
  --charcoal: #1d1d1b;
  --ash: #b6b6af;
  --beaker: 50px;
  --overlay: linear-gradient(to top, rgba(0, 9, 43, 0.5), rgba(0, 0, 0, 0));
  --box-shadow: 2px 4px 4px #00000030;
  --pattern-width: 600px;
}

* {
  font-family: "Open Sans", "Exo 2", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
  margin-bottom: 20px;
}

h1 {
  font-size: 60px;
  background: var(--digilab);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  margin-bottom: 30px;
}

h2 {
  font-size: 48px;
  color: var(--digilab);
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 20px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 24px;
}

p {
  font-size: 16px;
  max-width: 70ch;
}

a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--ocean);
}

a:not(.btn, .hero, .back):hover {
  opacity: 0.8;
  text-decoration: underline;
}

a.hero:hover {
  opacity: 0.5;
}

.heading {
  background: var(--digilab);
  border-radius: 5px;
  padding: 60px 10px;
  margin: 40px 0;
  text-align: center;
}

.heading h1 {
  margin: 0;
}

.heading h2 {
  color: var(--digilab);
}

/* Text fields */

.text-field {
  border-radius: 30px;
  font-size: 16px;
  outline: none;
}

.text-field:focus {
  border-color: 2px var(--digilab);
}

/* Buttons */

button,
.btn {
  padding: 10px 30px;
  border-radius: 6px;
  cursor: pointer;
}

button:disabled,
.btn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn.primary {
  background: var(--digilab);
  color: #fff;
  border: 2px solid var(--digilab);
}

.btn.primary:hover {
  background: #fff;
  color: var(--digilab);
}

.btn.charcoal {
  background: var(--charcoal);
  color: #fff;
  border: 2px solid var(--charcoal);
}

.btn.charcoal:hover {
  background: #fff;
  color: var(--charcoal);
}

.btn.blue {
  background: var(--ocean);
  border: 2px solid var(--ocean);
  color: #fff;
}

.btn.blue:hover {
  background: #fff;
  color: var(--ocean);
}

.btn.green {
  background: var(--spring);
  color: #fff;
  border: 2px solid var(--spring);
}

.btn.green:hover {
  background: #fff;
  color: var(--spring);
}

.btn.white {
  background: #fff;
  color: var(--digilab);
  border: 2px solid #fff;
}

.btn.white:hover {
  background: var(--digilab);
  color: #fff;
}

.btn.whiteoutline {
  border: 2px solid #fff;
  color: #fff;
}

.btn.whiteoutline:hover {
  border-color: var(--digilab);
  color: var(--digilab);
  background: #fff;
}

.cta {
  margin-top: 40px;
}

/* Navigation */

nav {
  height: 96px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: var(--box-shadow);
  position: sticky;
  top: 0;
  z-index: 100;
}

nav a,
footer a {
  color: var(--charcoal);
}

nav a.home img {
  height: 42px;
}

#search {
  height: 30px;
  margin: 6px;
}

/* Layout */

.container,
section {
  max-width: 1180px;
  margin: auto;
}

section {
  padding: 100px 0 130px 0;
}

nav .container {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.pages .home {
  margin-right: 30px;
  margin-top: 5px;
}

.pages,
.mobile {
  display: flex;
  align-items: center;
}

.pages > *,
.mobile > * {
  margin: 0 10px;
}

.mobile {
  margin-left: auto;
}

.homebox h3 {
  color: #fff;
}

.tokamask,
.tokadyne,
.fullgyro,
.homebox {
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Exo 2", sans-serif;
}

.homebox {
  background-color: white;
  /* background-image: url("assets/background.svg"); */
  background-size: cover;
  /* min-height: calc(100vh - 96px); */
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar a:hover {
  opacity: 1;
  text-decoration: none;
  transform: translateY(-6px);
  transition: all ease 0.5s;
}

.navbar a:hover > div {
  background-color: #ddd;
}

.navbar h3 {
  margin: auto;
  min-width: 214px;
  width: 214px;
  color: #000;
}

.writeup {
  text-align: justify;
  margin: auto 24px !important;
}

.back {
  font-size: 25px;
  color: #fff;
  border: 2px var(--digilab);
  margin-bottom: 30px;
  padding: 10px 14px 12px 10px;
  border-radius: 6px;
}

.back svg {
  margin-bottom: -3px;
}

.back:hover {
  background: var(--digilab);
  text-decoration: none;
}

.tokamask #wrapper,
.tokadyne #wrapper,
.fullgyro #wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 800px;
  padding: 20px 10px;
  max-width: 1180px;
  flex-direction: column;
  margin: auto;
  min-height: calc(100vh - 96px);
}

.tokamask #wrapper > div,
.tokadyne #wrapper > div,
.fullgyro #wrapper > div {
  padding: 30px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  box-sizing: content-box;
}

.tokamask #wrapper > div > div,
.tokadyne #wrapper > div > div,
.fullgyro #wrapper > div > div {
  padding: 20px 0;
}

.tokamask #wrapper > div > div h2,
.tokadyne #wrapper > div > div h2,
.fullgyro #wrapper > div > div h2 {
  text-align: center;
}

#tokagrid {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
}

#tokagrid > div:first-child > div {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

#graph {
  margin: 60px auto;
  color: var(--digilab);
}

#graph .dygraph-label-rotate-right {
  left: -18px !important;
  top: 100px !important;
}

#graph .dygraph-ylabel {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  font-weight: bold;
}

#graph .dygraph-xlabel {
  left: 140px;
  position: absolute;
  top: 20px;
  font-weight: bold;
}

#graph .dygraph-legend {
  top: -32px !important;
  position: absolute;
  left: 0px !important;
}

#tokaImg {
  margin-bottom: 10px;
  text-align: center;
}

.navbar img {
  object-fit: cover;
}

.circles {
  width: 200px;
  height: 200px;
  position: relative;
  perspective: 50000px;
  color: var(--digilab);
  margin: auto;
}

.circles span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 18.4%;
  height: 18.4%;
  border-radius: 100%;
  background: currentColor;
}

.circles div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 2vmin solid;
  border-top: 2vmin solid transparent;
  border-radius: 100%;
}

.circles div:nth-child(1) {
  -webkit-animation: anim-1 1s linear infinite;
  animation: anim-1 1s linear infinite;
}

.circles div:nth-child(2) {
  -webkit-animation: anim-2 1s linear infinite;
  animation: anim-2 1s linear infinite;
}

.circles div:nth-child(3) {
  -webkit-animation: anim-3 1s linear infinite;
  animation: anim-3 1s linear infinite;
}

.disclaimer p {
  margin-top: auto;
  font-size: 10px;
  color: #fff;
}

.js-plotly-plot,
.js-plotly-plot .plotly .user-select-none {
  margin: auto;
  display: block !important;
}

.image-gallery-content nav {
  position: initial;
  box-shadow: none;
}

.image-gallery-thumbnail.good {
  border-color: var(--good);
}

.image-gallery-thumbnail.bad {
  border-color: var(--bad);
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border-color: var(--digilab);
}

.image-gallery-right-nav,
.image-gallery-left-nav,
.image-gallery-icon {
  box-shadow: none;
}

.image-gallery-right-nav:hover,
.image-gallery-left-nav:hover,
.image-gallery-icon:hover {
  color: var(--digilab);
}

.image-gallery-slide {
  padding: 20px 15%;
}

.image-gallery-center.good {
  background: var(--good);
}

.image-gallery-center.bad {
  background: var(--bad);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 75px;
}

.image-gallery-slides {
  max-height: 400px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 360px;
}

@-webkit-keyframes anim-1 {
  from {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
  }

  to {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes anim-1 {
  from {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
  }

  to {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@-webkit-keyframes anim-2 {
  from {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
  }

  to {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes anim-2 {
  from {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
  }

  to {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@-webkit-keyframes anim-3 {
  from {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes anim-3 {
  from {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}

@media only screen and (max-width: 900px) {
  .navbar p {
    margin: auto !important;
  }

  .navbar img {
    margin-top: 20px;
  }

  .navbar h3 {
    width: auto;
  }

  .tokamask #wrapper > div,
  .tokadyne #wrapper > div,
  .fullgyro #wrapper > div {
    box-sizing: border-box;
    width: calc(100% - 40px);
  }
}

/* .fullgyro {
  background-color: var(--digilab);
} */

.page-display {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* position of title on the page*/
.title-box {
  margin-left: 25%;
  text-align: center;
  padding-top: 20px;
}

/* layout of the left coloumn (input parameter 'bar')*/
.parameter-column {
  width: 25%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
}

/* style of slider */
.slider-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
